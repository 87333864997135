/*圣诞主题 贴画 */  
/*圣诞头部*/
.top_nav_bar {	
	background: url(../../img/merry/merry_top_nav.png);
	background-position: center center); 
}
/*圣诞菜单部*/
.top_menu_nav .container{
	position: relative;
}
.top_menu_nav .container .merry_gift{
	position: absolute;
	top:0;		
}
.top_menu_nav .container .merry_gift1{	
	left:-20%;
	animation: Scalegift 2500ms ease-out infinite alternate;	
}
.top_menu_nav .container .merry_gift4{	
	left:-13%;
	animation: Scalegift2 2500ms ease-out infinite alternate;
}
.top_menu_nav .container .merry_gift2{	
	right:-13%;
	animation: Scalegift2 2500ms ease-out infinite alternate;
}
.top_menu_nav .container .merry_gift3{	
	right:-20%;
	animation: Scalegift 2500ms ease-out infinite alternate;	
}

/*圣诞search部*/
.top_search_box .container{
	position: relative;
}
.top_search_box .merry_search_icon1{
	position: absolute;
	top:3px;
	left:84px;
	z-index: 5;
}
.top_search_box .merry_search_icon2{
	position: absolute;
	top:4px;
	left:120px;
}
@keyframes Scalegift{
    0%{            
        transform:rotate(20deg); 
        transform-origin:top;        
    }            
    100%{
        transform:rotate(-20deg); 
        transform-origin:top;     
    }
}
@keyframes Scalegift2{
    0%{            
        transform:rotate(-15deg); 
        transform-origin:top;        
    }            
    100%{
        transform:rotate(6deg); 
        transform-origin:top;     
    }
}
@keyframes oldmanGo{
    0%{       
       transform: translateX(400px);	
    }                      
    100%{    	
       transform: translateX(0px);	
    	
    }
}

@keyframes opcityShow{
    0%{ 
        opacity: 0;
    }                    
    100%{
    	opacity: 1;	    	
    }
}
@keyframes scaleShow{
    0%{ 
        transform: scale(0);
        opacity: 0;
    } 
    70%{
    	transform: scale(0.8);    	
    }
    80%{
    	transform: scale(1.1);  
    	opacity: 1;
    }                 
    100%{
    	opacity: 1;
    	transform: scale(1); 	
    }
}
.merry_oldman{
	width: 218px;
	height: 143px;
	position: absolute;
	bottom: 0;
	right:-218px;	
	animation: oldmanGo 1000ms ease-in 1;
}
.merry_oldman .oldman_snow_back{
	position: absolute;
	bottom: 0;
	left: 0;;	
	opacity: 0;
	animation: opcityShow 200ms ease-in 1 forwards;
	animation-delay:1s;
}
.merry_oldman .merry_tips{
	position: absolute;
	top:0;
	left:0;	
	opacity: 0;
	animation: scaleShow 200ms ease-in 1 forwards;
	animation-delay:1s;	
}
.merry_oldman .oldman{
	width: 118px;
	height: 112px;
	background: url(../../img/merry/merry_top_nav_left.png);
	position: absolute;
	top:20px;
	left:85px;	
}

/*雪花效果*/
body{
	position: relative;
}
body:after{
	content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: -50%;
    width: 200%;
    height: 100%;
    z-index: -2;   
    background-size: 343px 325px;
    background-image: url(../../img/merry/snow.png);   
    animation: snow 18s linear infinite;
    opacity: 1;     
    transition: opacity 5s;
    
}

@keyframes snow{
    0% { background-position: center 0, 0 0;}
    100% { background-position: center 885px, 0 0;}
}

/*select框*/
.select_area{
	background:url('../img/merry/merry_select_area_bg.png') repeat #f5f8fa;   
}
.select_area>.container{
	position: relative;
}

.select_area>.container{
    background-color: #f5f8fa;
    position: relative;
}
.select_area>.container .merry_select_area_snow{
    position: absolute;
    bottom: 0;
    left:50%;
    margin-left:-126px;
}

.select_area .tab-address {
    width: 33px !important;
    height: 27px !important;
    margin-right: 7px;   
    background: url(../../img/merry/merry_tree.png) no-repeat !important;
    position: relative;
    top:5px;
}
.select_area .tab-address b{
   display: none;
}

